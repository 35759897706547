import React from 'react'
import './Footer.sass'
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";

const Footer = class extends React.Component {
   render() {
      return (
         <footer class="py-5 text-center">
            <div class="container">
               <div class="social-icons mb-3">
                  <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/">
                     <TiSocialFacebook/>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/">
                     <TiSocialInstagram/>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/">
                     <TiSocialTwitter/>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/">
                     <TiSocialYoutube/>
                  </a>
               </div>
               <p class="mt-0 mb-0"> 
                  © Copyright 2023 Markus Piipari Ltd &amp; Lumoa Ltd. All Rights Reserved.
               </p>
            </div>
         </footer>
      )
   }
}

export default Footer
